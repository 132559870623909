<template>
    <Modal ref="modalAgregarFuncionario" tamano="modal-lg" titulo="Agregar funcionario de talleres" adicional="Crear" @adicional="createFunctionary" @cancelar="handleClose">
        <ValidationObserver ref="validator">
            <form class="row justify-content-center m-3 f-15" autocomplete="off">
                <div class="col-12">
                    <required text="Campos obligatorios"/>
                </div>
                <div class="row w-100 justify-content-center">
                    <div class="my-2 f-10">
                        <ValidationProvider name="imagen" rules="required" v-slot="{errors}">
                            <p class="input-label-top text-center">Foto de perfil <required/></p>
                            <clean-cropper 
                            class="cropper-proveedor border cr-pointer mx-auto br-50"
                            ref="cleanCropper"
                            style="width: 100px; height: 100px;" 
                            v-model="image" 
                            :options="slimOptions" 
                            />   
                            <vee-error :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 my-2">
                    <ValidationProvider name="nombre" rules="required" v-slot="{errors}">
                        <p class="input-label-top">Nombre <required/></p>
                        <el-input v-model="model.nombre_completo" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider name="username" rules="required" v-slot="{errors}">
                        <p class="input-label-top">Username <required/></p>
                        <el-input v-model="model.username" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider name="contraseña" rules="required|min:8" v-slot="{errors}">
                        <p class="input-label-top">Contraseña <required/></p>
                        <el-input v-model="model.password" type="password" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider name="correo personal" rules="required|email|max:255" v-slot="{errors}">
                        <p class="input-label-top">Correo personal</p>
                        <el-input v-model="model.correo_personal" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider name="número de contacto" rules="numeric|min:6|max:255" v-slot="{errors}">
                        <p class="input-label-top">Número de contacto</p>
                        <el-input v-model="model.numero_contacto" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider name="correo personal" rules="email|max:255" v-slot="{errors}">
                        <p class="input-label-top">Correo corporativo</p>
                        <el-input v-model="model.correo_corporativo" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider name="número de contacto" rules="numeric|min:6|max:255" v-slot="{errors}">
                        <p class="input-label-top">Número de telefono corporativo</p>
                        <el-input v-model="model.telefono_corporativo" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </form>
        </ValidationObserver>
    </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const model = {
    nombre_completo: '',
    username: '',
    password: '',
    foto_perfil: null,
    correo_personal: '',
    telefono_personal: '',
    correo_corporativo: '',
    telefono_corporativo: '',
}
export default {
    data(){
        return {
            model: {...model},
            slimOptions: {
                ratio: '1:1',
                label:'Añadir imagen de taller',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            image: null
        }
    },
    computed:{
        ...mapGetters({
            key: 'files/files/key'
        })
    },
    methods: {
        ...mapActions({
            Action_save_file_to_aws: 'files/files/Action_save_file_to_aws',
            Action_save_functionary: 'talleres/talleres/Action_save_functionary'
        }),
        toggle(){
            this.model = {...model}
            this.reset()
            this.$refs.modalAgregarFuncionario.toggle()
        },
        handleClose(){
            this.$refs.cleanCropper.reset()
        },
        async createFunctionary(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return 

            const base64Image = this.image

            if(base64Image) {
                await this.Action_save_file_to_aws({ 
                    file: await this.base64ToFile(base64Image),
                    path: '/talleres' 
                });
            }
            
            this.model.foto_perfil = base64Image ? this.key : null
            await this.Action_save_functionary(this.model)
            
            this.$emit('addWorkshop')
        },
        async reset(){
            this.model = {...model}
            this.$refs.validator.reset()
            this.$refs.cleanCropper.reset()
        }
    }
}
</script>