var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalAgregarTaller",attrs:{"tamano":"modal-lg","titulo":(_vm.tituloModal + " taller"),"adicional":_vm.botonModal},on:{"adicional":_vm.saveWorkshop}},[_c('ValidationObserver',{ref:"validator"},[_c('div',{staticClass:"row justify-content-center m-3 f-15"},[_c('div',{staticClass:"row w-100 justify-content-center"},[_c('div',{staticClass:"my-2 f-10"},[_c('ValidationProvider',{attrs:{"name":"imagen","rules":{'required' : !_vm.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('clean-cropper',{ref:"cleanCropper",staticClass:"cropper-proveedor border cr-pointer mx-auto br-5",staticStyle:{"width":"122px","height":"98px"},attrs:{"options":_vm.slimOptions,"image":_vm.imagen},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)]),_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"name":"nombre del taller","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Nombre del taller")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Ciudad")]),_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","placeholder":"Seleccionar ciudad","remote":"","remote-method":_vm.buscarCiudad,"loading":_vm.loading,"size":"small"},model:{value:(_vm.model.id_ciudad),callback:function ($$v) {_vm.$set(_vm.model, "id_ciudad", $$v)},expression:"model.id_ciudad"}},_vm._l((_vm.select_cities),function(item){return _c('el-option',{key:item.id,attrs:{"label":((item.ciudad) + " - " + (item.estado)),"value":item.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"dirección del taller","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Dirección del taller")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.direccion),callback:function ($$v) {_vm.$set(_vm.model, "direccion", $$v)},expression:"model.direccion"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"nombre del responsable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Nombre del responsable")]),_c('div',{staticClass:"d-middle"},[_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Seleccionar proyecto","size":"small"},model:{value:(_vm.model.id_responsable),callback:function ($$v) {_vm.$set(_vm.model, "id_responsable", $$v)},expression:"model.id_responsable"}},_vm._l((_vm.select_responsible_workshops),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('div',{staticClass:"icon-option"},[_c('i',{staticClass:"icon-plus-circle cr-pointer f-30 pl-2",on:{"click":function($event){return _vm.$emit('addFunctionary')}}})])],1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"número de contacto","rules":"required|min:6|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Número de contacto")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.numero_contacto),callback:function ($$v) {_vm.$set(_vm.model, "numero_contacto", $$v)},expression:"model.numero_contacto"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }