<template>
    <modal ref="modalAgregarTaller" tamano="modal-lg" :titulo="`${tituloModal} taller` " :adicional="botonModal" @adicional="saveWorkshop">
        <ValidationObserver ref="validator">
            <div class="row justify-content-center m-3 f-15">
                <div class="row w-100 justify-content-center">
                    <div class="my-2 f-10">
                        <ValidationProvider name="imagen" :rules="{'required' : !id }" v-slot="{errors}">
                            <clean-cropper 
                            class="cropper-proveedor border cr-pointer mx-auto br-5"
                            ref="cleanCropper"
                            style="width: 122px; height: 98px;" 
                            v-model="image" 
                            :options="slimOptions" 
                            :image="imagen"
                            />   
                            <vee-error :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 my-2">
                    <ValidationProvider name="nombre del taller" rules="required" v-slot="{errors}">
                        <p class="input-label-top">Nombre del taller</p>
                        <el-input v-model="model.nombre" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider name="ciudad" rules="required" v-slot="{errors}">
                        <p class="input-label-top">Ciudad</p>
                        <el-select v-model="model.id_ciudad" filterable placeholder="Seleccionar ciudad" remote :remote-method="buscarCiudad" :loading="loading" size="small" class="w-100">
                            <el-option v-for="item in select_cities" :key="item.id" :label="`${item.ciudad} - ${item.estado}` "  :value="item.id"/>
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider name="dirección del taller" rules="required" v-slot="{errors}">
                        <p class="input-label-top">Dirección del taller</p>
                        <el-input v-model="model.direccion" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider name="nombre del responsable" rules="required" v-slot="{errors}">
                        <p class="input-label-top">Nombre del responsable</p>
                        <div class="d-middle">
                            <el-select v-model="model.id_responsable" placeholder="Seleccionar proyecto" size="small" class="w-100">
                                <el-option v-for="item in select_responsible_workshops" :key="item.id" :label="item.nombre" :value="item.id" />
                            </el-select>
                            <div class="icon-option">
                                <i class="icon-plus-circle cr-pointer f-30 pl-2" @click="$emit('addFunctionary')"/>
                            </div>
                        </div>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider name="número de contacto" rules="required|min:6|max:20" v-slot="{errors}">
                        <p class="input-label-top">Número de contacto</p>
                        <el-input v-model="model.numero_contacto" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const model = {
    nombre: '',
    imagen: null,
    id_ciudad: null,
    direccion: '',
    id_responsable: null,
    numero_contacto: '',
}
export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Añadir imagen de taller',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            model: {...model},
            image: null,
            loading:false,
            imagen: null,
        }
    },
    computed: {
        ...mapGetters({
            select_responsible_workshops: 'selects/selects/select_responsible_workshops',
            select_cities: 'selects/selects/select_city_paginated',
            workshop: 'talleres/ver/workshop',
            key:'files/files/key',
        }),
        id:{
            get(){
                return this.$store.getters['talleres/talleres/id_taller']
            },
            set(val){
                this.$store.commit('talleres/talleres/set_id_taller', val)
            }
        },
        tituloModal(){
            return this.$route.name == 'talleres.main' ? 'Crear' : 'Editar'
        },
        botonModal(){
            return this.$route.name == 'talleres.main' ? 'Crear' : 'Guardar'
        },
    },
    methods: {
        ...mapActions({
            Action_save_workshop: 'talleres/talleres/Action_save_workshop',
            Action_update_workshop: 'talleres/talleres/Action_update_workshop',
            Action_save_file_to_aws: 'files/files/Action_save_file_to_aws',
            Action_get_selects_city_paginated: 'selects/selects/Action_get_selects_city_paginated',
        }),
        toggle(){
            this.model = {...model}
            this.$refs.validator.reset()
            this.$refs.cleanCropper.reset()
            this.$refs.modalAgregarTaller.toggle()
        },
        async edit(){
            this.id = this.workshop.id
            this.model = {
                nombre: this.workshop.nombre,
                direccion: this.workshop.direccion,
                imagen: this.workshop.imagen,
                id_ciudad: this.workshop.id_ciudad,
                id_responsable: this.workshop.id_responsable,
                numero_contacto: this.workshop.telefono,
            }
            this.imagen = this.workshop.imagen
            this.$refs.modalAgregarTaller.toggle()
        },
        async saveWorkshop(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return
            
            const base64Image = this.image

            if(base64Image) {
                console.log(await this.base64ToFile(base64Image));
                await this.Action_save_file_to_aws({ 
                    file: await this.base64ToFile(base64Image),
                    path: '/talleres' 
                });
            }
            
            this.model.imagen = base64Image ? this.key : this.imagen

            this.id 
                ? await this.Action_update_workshop(this.model)
                : await this.Action_save_workshop(this.model)

            this.reset()
        },
        reset(){
            this.id = null
            this.model = {...model}
            this.$refs.validator.reset()
            this.$refs.cleanCropper.reset()
            this.toggle()
        },
        async buscarCiudad(item){
            this.loading = true
            await this.Action_get_selects_city_paginated({query: item})
            this.loading = false
        },
    }
}
</script>

<style lang="css" scoped>
</style>
