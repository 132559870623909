var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{ref:"modalAgregarFuncionario",attrs:{"tamano":"modal-lg","titulo":"Agregar funcionario de talleres","adicional":"Crear"},on:{"adicional":_vm.createFunctionary,"cancelar":_vm.handleClose}},[_c('ValidationObserver',{ref:"validator"},[_c('form',{staticClass:"row justify-content-center m-3 f-15",attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"col-12"},[_c('required',{attrs:{"text":"Campos obligatorios"}})],1),_c('div',{staticClass:"row w-100 justify-content-center"},[_c('div',{staticClass:"my-2 f-10"},[_c('ValidationProvider',{attrs:{"name":"imagen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top text-center"},[_vm._v("Foto de perfil "),_c('required')],1),_c('clean-cropper',{ref:"cleanCropper",staticClass:"cropper-proveedor border cr-pointer mx-auto br-50",staticStyle:{"width":"100px","height":"100px"},attrs:{"options":_vm.slimOptions},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)]),_c('div',{staticClass:"col-12 my-2"},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Nombre "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.nombre_completo),callback:function ($$v) {_vm.$set(_vm.model, "nombre_completo", $$v)},expression:"model.nombre_completo"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Username "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", $$v)},expression:"model.username"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"contraseña","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Contraseña "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"type":"password","size":"small"},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"correo personal","rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Correo personal")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.correo_personal),callback:function ($$v) {_vm.$set(_vm.model, "correo_personal", $$v)},expression:"model.correo_personal"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"número de contacto","rules":"numeric|min:6|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Número de contacto")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.numero_contacto),callback:function ($$v) {_vm.$set(_vm.model, "numero_contacto", $$v)},expression:"model.numero_contacto"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"correo personal","rules":"email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Correo corporativo")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.correo_corporativo),callback:function ($$v) {_vm.$set(_vm.model, "correo_corporativo", $$v)},expression:"model.correo_corporativo"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"número de contacto","rules":"numeric|min:6|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Número de telefono corporativo")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.telefono_corporativo),callback:function ($$v) {_vm.$set(_vm.model, "telefono_corporativo", $$v)},expression:"model.telefono_corporativo"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }