<template>
    <section class="talleres-main px-3 ">
        <navbar-admin titulo="Talleres"  />
        <section class="bg-white p-3 br-4 shadowbox">
            <section class="tipos-servicios">
                <titulo-divisor titulo="Tipos de servicios" class="mx-0"/>
                <pills class="mt-4" :iterable="services_types_graph" label="servicio" counter="servicios_vigentes" tooltip labels/>
            </section>
            <titulo-divisor titulo="Servicios por mes" class="mx-0"/>
            <echart :options="configChartCotizaciones" />
        </section>
        <section class="bg-white p-3 br-4 shadowbox mt-4">
            <titulo-divisor titulo="Lista de talleres">
                <div class="row mr-1">
                    <div class="col-auto px-1">
                        <input-search
                        v-model="query"
                        placeholder="Buscar taller"
                        :search="beforeSearch"
                        auto-search
                        />
                    </div>
                    <div class="col-auto pl-3">
                        <button class="btn btn-general f-12 px-3"
                            :class="permitAction('taller.crear') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('taller.crear', crearTaller)"
                        >Crear taller</button>
                    </div>
                    <filter-indicator :count="filterCount" @openModalFilter="openModalFilter" @clearFilter="clearFilter"/>
                </div>
            </titulo-divisor>
            <tabla-general :mostrarBuscador="false" :usar-paginacion="false" alturaTabla="calc(100vh - 500px)" :data="workshops" >
                <el-table-column label="Nombre" prop="nombre">
                    <template slot-scope="scope">
                        <p class="f-12 text-muted2 tres-puntos cr-pointer f-600" @click="verInfoTaller(scope.row.id)">
                            {{ scope.row.nombre }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="Dirección" prop="direccion">
                    <template slot-scope="scope">
                        <p class="f-12 text-muted2">
                            {{ scope.row.direccion }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="Teléfono" prop="telefono" width="140">
                    <template slot-scope="scope">
                        <p class="f-12 text-muted2">
                            {{ scope.row.telefono }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="Responsable" prop="responsable">
                    <template slot-scope="scope">
                        <p class="f-12 text-muted2">
                            {{ scope.row.responsable }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="Servicios vigentes" prop="serviciosVigentes" align="center" width="210">
                    <template slot-scope="scope">
                        <div class="bg-general wh-30 br-4 mx-auto text-white d-middle-center">
                            {{ scope.row.servicios_vigentes || '0' }}
                        </div>
                    </template>
                </el-table-column>
            </tabla-general>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <pagination :pagination="pagination" layout="prev, pager, next" @paginate="paginateWorkshops"/>
                </div>
            </div>
        </section>
        <modal-filter-workshops ref="modalFilter"/>
        <modal-agregar-taller ref="abrirAgregarTaller" @addFunctionary="showModalFunctionary"/>
        <modal-agregar-funcionario ref="abrirAgregarFuncionario" @addWorkshop="showModalWorkshop"/>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import modalFilterWorkshops from './partials/modalFilterWorkshops'
import modalAgregarTaller from './partials/modalAgregarTaller'
import modalAgregarFuncionario from './partials/modalAgregarFuncionario'
export default {
    components: {
        modalFilterWorkshops,
        modalAgregarTaller,
        modalAgregarFuncionario
    },
    data(){
        return{
            buscarListado:'',
            configChartCotizaciones: {
                tooltip: {
                    formatter: (e) => (`<div class="f-12 lh-12"><p class="w-100 text-center">${e.name}</p><span>${e.value} servicios</span></div>`),
                    position: (point) => ([point[0] - 40, point[1] - 55]),
                    triggerOn: 'mousemove',
                    backgroundColor: '#3B3B3B',
                    borderColor: "#FFFFFF",
                    textStyle: {
                        color: '#FFFFFF'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        show: true,
                        lineStyle: { color: '#9B9B9B' }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: true,
                        lineStyle: { color: '#9B9B9B' }
                    }
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                        symbol: 'circle',
                        symbolSize: 12
                    }
                ]
            }
        }
    },
    computed: {
        ...mapGetters({
            workshops: 'talleres/talleres/workshops',
            pagination: 'talleres/talleres/pagination',
            filter: 'talleres/talleres/filter',
            services_graph: 'talleres/talleres/services_graph',
            services_types_graph: 'talleres/talleres/services_types_graph'
        }),
        filterCount(){
            return this.filter.count()
        },
        query: {
            get(){
                return this.$store.getters['talleres/talleres/query']
            }, 
            set(val){
                this.$store.commit('talleres/talleres/set_query', val)
            }
        },
    },
    watch: {
        services_graph(val){
            this.configChartCotizaciones.xAxis.data = val.map(el => el.mes_abre)
            this.configChartCotizaciones.series[0].data = val.map(el => el.numero_servicios)
        },
    },
    async created(){
        this.getBreadcumbs(['talleres.main'])
        this.getSelects(['Action_get_selects_responsible_workshops', 'Action_get_selects_city_paginated'])
        this.Action_get_select_responsible_users()
        this.Action_get_services_types_graph()
        this.Action_get_services_graph()
        await this.Action_get_service_range()
        await this.Action_get_workshops()
        this.clearFilter()
    },
    methods:{
        ...mapActions({
            getSelects: 'selects/selects/sync',
            clearFilter: 'talleres/talleres/clear_filter',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            Action_get_workshops: 'talleres/talleres/Action_get_workshops',
            Action_get_service_range: 'talleres/talleres/Action_get_service_range',
            Action_get_services_graph: 'talleres/talleres/Action_get_services_graph',
            Action_get_services_types_graph: 'talleres/talleres/Action_get_services_types_graph',
            Action_get_select_responsible_users: 'talleres/talleres/Action_get_select_responsible_users',
        }),
        beforeSearch(){
            this.Action_get_workshops()
        },
        openModalFilter(){
            this.$refs.modalFilter.toggle()
        },
        filtrarMateriales(){
            this.$refs.abrirModalFiltros.toggle()
            this.cantFiltros = 1
        },
        paginateWorkshops(page){
            this.Action_get_workshops({ page })
        },
        limpiarFiltro(){
            this.$refs.abrirModalFiltros.toggle()
            this.cantFiltros = 0
        },
        crearTaller(){
            this.$refs.abrirAgregarTaller.toggle()
        },
        verInfoTaller(id){
            this.$router.push({ name: 'talleres.ver', params: { id } })
        },
        showModalFunctionary(){
            this.$refs.abrirAgregarFuncionario.toggle()
            this.$refs.abrirAgregarTaller.toggle()
        },
        showModalWorkshop(){
            this.$refs.abrirAgregarTaller.toggle()
            this.$refs.abrirAgregarFuncionario.toggle()
            this.getSelects(['Action_get_selects_responsible_workshops'])
        }
    }
}
</script>

<style lang="scss" scoped>
.talleres-main{
    .shadowbox{
        box-shadow: 0px 3px 6px #00000029;
    }
    .contenedor-servicios{
        height: 10px;
        position: relative;
        @for $servicio from 1 through 7 {
            .servicio:nth-child(#{$servicio}) {
                z-index: 10 - $servicio !important;
            }
        }
        .servicio{
            height: 10px;
            border-radius: 50rem;
            position: absolute;
        }
    }
}
</style>