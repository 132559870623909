<template>
    <!-- partials -->
    <modal-lateral ref="modalFilter" titulo="Filtro">
        <div class="row mx-0 justify-content-center my-2">
            <div class="col-10 mb-4">
                <p class="input-label-top">Nombre del taller</p>
                <el-input v-model="filter.nombre_taller" class="w-100" size="small" />
            </div>
        </div>
        <div class="row mx-0 justify-content-center my-2">
            <div class="col-10 mb-4">
                <p class="input-label-top">Dirección</p>
                <el-input v-model="filter.direccion" class="w-100" size="small" />
            </div>
        </div>
        <div class="row mx-0 justify-content-center my-2">
            <div class="col-10 mb-4">
                <p class="input-label-top">Telefono</p>
                <el-input v-model="filter.telefono" class="w-100" size="small" />
            </div>
        </div>
        <div class="row mx-0 justify-content-center my-2">
            <div class="col-10 mb-4">
                <p class="input-label-top">Responsables</p>
                <el-select v-model="filter.responsables" multiple :collapse-tags="true" class="w-100 mb-3" size="small">
                    <el-option v-for="(responsable, index) in select_responsible_workshops" :key="index" :label="responsable.nombre" :value="responsable.id"/>
                </el-select>
                <section class="d-flex flex-wrap gap-1">
                    <template v-for="(responsable, index) in select_responsible_workshops" >
                        <div v-if="filter.responsables.includes(responsable.id)" :key="index" class="d-flex align-items-center border br-5 px-2" >
                            <span class="f-12">{{ responsable.nombre }}</span>
                            <i class="icon-close-circle f-20 text-ddd cr-pointer" @click="removeResponsible(responsable.id)"></i>
                        </div>
                    </template>
                </section>
            </div>
        </div>
        <div class="row mx-0 justify-content-center my-2">
            <div class="col-10 mb-4">
                <p class="input-label-top">Rango de valor</p>
                <el-slider v-model="range_services" range :format-tooltip="formatTooltip" :min="range.min" :max="range.max"/>
            </div>
        </div>
        <div class="botones">
            <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearFilter"> Limpiar </button>
            <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterWorkshops"> Filtrar </button>
        </div>
    </modal-lateral>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            select_responsible_workshops: 'talleres/talleres/select_responsible_users',
            range: 'talleres/talleres/range',
        }),
        filter: {
            get(){
                return this.$store.getters['talleres/talleres/filter']
            },
            set(val){
                this.$store.commit('talleres/talleres/set_filter', val)
            }
        },
        range_services:{
            get(){
                return [this.filter.servicios_min, this.filter.servicios_max]
            },
            set(val){
                this.filter.servicios_min = val[0]
                this.filter.servicios_max = val[1]
            }
        }
    },
    watch:{
        range(val){
            this.range_services = [
                val.min,
                val.max
            ]
        }
    },
    methods: {
        ...mapActions({
            clearFilter: 'talleres/talleres/clear_filter',
            Action_get_workshops: 'talleres/talleres/Action_get_workshops'
        }),
        toggle(){
            this.$refs.modalFilter.toggle()
        },
        async filterWorkshops(){
            await this.Action_get_workshops()
            this.toggle()
        },
        removeResponsible(id){
            this.filter.responsables = this.filter.responsables.filter(el => el != id)
        },
        formatTooltip(e){
            return this.formatoMoneda(e)
        }
    }
}
</script>